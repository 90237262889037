/**
 * App selectize Object
 *
 * @type object
 */
App.selectize = {

    /**
     * Object properties
     */
    results : [],

    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function()
    {
        App.selectize.handle();
    },


    /**
     * Handle the selectize element
     */
    handle : function()
    {
        $('.selectize').selectize({
            delimiter : ',',
            persist   : false,
            create    : function(input) {
                return {
                    value : input,
                    text  : input
                }
            },
            load: function(query, callback) {

                var url = $(this.$input).data('url');

                if (!query.length || url === undefined) return callback();

                // Setup the ajax call
                var request = $.ajax({
                    url         : url,
                    type        : 'POST',
                    data        : {
                        tag: query,
                        _token: App.helper.getToken(),
                    }
                });

                // Ajax Success
                request.success(function(data)
                {
                    var list =[];

                    if (!data.tags.length) {
                        callback();
                    }

                    $.each(data.tags, function(i) {

                        list.push({
                            text: this,
                            value: this
                        });

                    });

                    console.log(list);

                    callback(list);

                });

            }
        });
    }
};