/**
 * App chart Object
 *
 * @type object
 */
App.chart = {

    /**
     * Line Chart
     *
     * @param id
     * @param labels
     * @param chart_data
     * @param reverse
     * @param tooltip
     *
     * @return chart
     */
    line: function(id, labels, chart_data, reverse, tooltip) {

        if (reverse == true) {
            labels.reverse();

            $.each(chart_data, function(i, object) {
                object.reverse();
            });

        }

        tooltip = this.tooltip(tooltip);

        var chart = new Chartist.Line('#ct-chart-' + id, {
            labels: labels,
            series: chart_data
        }, {
            height: '253px',
            plugins: [
                Chartist.plugins.tooltip(tooltip)
            ]
        });

        chart.on('draw', function(data) {

            if (data.type === 'point') {

                var circle = new Chartist.Svg('circle', {
                    cx: [data.x],
                    cy: [data.y],
                    r: [7],
                }, 'ct-circle ct-point').attr({
                    'ct:value': [data.value.x, data.value.y].filter(Chartist.isNum).join(','),
                    'ct:meta': data.meta
                });

                data.element.replace(circle);
            }
        });

        return chart;
    },


    /**
     * Bar Chart
     *
     * @param id
     * @param labels
     * @param chart_data
     * @param reverse
     * @param tooltip
     *
     * @return chart
     */
    bar: function(id, labels, chart_data, reverse, tooltip) {

        if (reverse == true) {
            labels.reverse();

            $.each(chart_data, function(i, object) {
                object.reverse();
            });
        }

        tooltip = this.tooltip(tooltip);

        var chart = new Chartist.Bar('#ct-chart-' + id, {
            labels: labels,
            series: chart_data
        }, {
            height: '253px',
            plugins: [
                Chartist.plugins.tooltip(tooltip)
            ]
        });

        return chart;
    },


    /**
     * Pie Chart
     *
     * @param id
     * @param chart_data
     * @param tooltip
     *
     * @return chart
     */
    pie: function(id, chart_data, tooltip) {

        tooltip = this.tooltip(tooltip);

        new Chartist.Pie('#ct-chart-' + id, {
            series: chart_data
        }, {
            donut: true,
            donutWidth: 60,
            height: '253px',
            showLabel: false,
            plugins: [
                Chartist.plugins.tooltip(tooltip)
            ]
        });
    },


    /**
     * Tooltips
     *
     * @param tooltip
     *
     * @return object
     */
    tooltip: function(tooltip) {
        if (tooltip === undefined) {
            return {};
        }

        return tooltip;
    }
 };