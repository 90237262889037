/**
 * App Overlay Object
 *
 * @type object
 */
App.overlay = function()
{
    /**
     * Constructor
     *
     * @param name
     * @returns {*}
     */
    this.init = function()
    {
        this._name = this.generateName();

        this._load_trigger_name    = 'overlayLoaded';
        this._success_trigger_name = 'overlaySuccess';
        this._failed_trigger_name  = 'overlayFailed';
        this._success_message      = 'Record successfully updated';
        this._failed_message       = 'Unable to update record. Please contact support for more assistance';

        this.create();
        this.setLayout();
        this.handleCloseBtn();
        this.handlePrimaryBtn();

        return this;
    };


    /**
     * Create
     */
    this.create = function()
    {
        this._app_container = $('#app-container');
        this._element       = $('.' + this._name);

        if (!this._element.length) {
            $('body').append('<div class="overlay ' + this._name + ' "></div>');
            this._element = $('.' + this._name);

        } else {
            this.reset();
        }

        return this;
    };


    /**
     * Load
     */
    this.load = function(ajax_url)
    {
        this.show();
        App.blockUI(this._content);

        var that = this;

        $.ajax({
            url         : ajax_url,
            type        : 'GET',
            contentType : false,
            processData : false,

            success : function(result) {
                that._content.append(result);
                App.select2.init();
                App.bootstrap.handleSelectBox();
                App.jquery.handleRedactor();
                App.unblockUI(that._content);
                $.event.trigger(that._load_trigger_name, [result, that]);
            },

            error : function(result, status, errorThrown) {
                that.errors(result, status, errorThrown);
            },
        });

        return this;
    };


    /**
     * Reset
     */
    this.reset = function()
    {
        this._element.html('');

        return this;
    };


    /**
     * Show
     */
    this.show = function()
    {
        var that = this;
        this._app_container.hide();

        this._element
            .removeClass('closed')
            .addClass('opening');

        setTimeout(function() {
            that._element
                .removeClass('opening')
                .addClass('open');
        }, 500);

        return this;
    };


    /**
     * Hide
     */
    this.hide = function()
    {
        var that = this;
        this._app_container.show();

        this._element.removeClass('open').addClass('closing');

        setTimeout(function() {
            that._element
                .removeClass('closing')
                .addClass('closed')
                .remove();

        }, 500);

        return this;
    };


    /**
     * Garbage collector
     */
    this.destroy = function()
    {
        for (var i = 0; i < overlayInstances; i++) {
            name += possible.charAt(Math.floor(Math.random() * possible.length));
        }
    }


    /**
     * Generate a random name
     *
     * @returns {string}
     */
    this.generateName = function()
    {
        var name     = "overlay-";
        var possible = "abcdefghijklmnopqrstuvwxyz0123456789";

        for (var i = 0; i < 5; i++) {
            name += possible.charAt(Math.floor(Math.random() * possible.length));
        }

        return name;
    };


    /**
     * Errors
     */
    this.errors = function(result, status, errorThrown)
    {
        var errors = false;
        if (result.responseJSON !== undefined && typeof result.responseJSON.errors !== 'undefined') {
            errors = result.responseJSON.errors;

        } else {
            var message = jQuery.parseJSON(result.responseText);
            if (message.errors !== undefined) {
                errors = message.errors;
            }
        }

        if (errors) {
            $.each(errors, function(key, error) {
                App.log('Error: ' + error);
                App.bootstrap.notifyUser('Error: <strong>' + error + '</strong>', 'danger');
            });

        } else {
            App.log('Error: ' + errorThrown);
            App.bootstrap.notifyUser('Error: <strong>' + errorThrown + '</strong>', 'danger');
        }

        return this;
    };


    /**
     * The overlay class
     */
    this.setClass = function(css_class)
    {
        if (css_class) {
            this._css_class = css_class.replace(/\s+/g, '-').toLowerCase();
            this._element.addClass(this._css_class);
        }

        return this;
    };


    /**
     * The title
     */
    this.setTitle = function(input)
    {
        if (input) {
            this._title.html(input);
        }

        return this;
    };


    /**
     * The summary
     */
    this.setSummary = function(input)
    {
        if (input) {
            this._summary.html('<div class="col-md-12">' + input + '</div>');
            this._summary.show();
        }

        return this;
    };


    /**
     * The secondary button text
     */
    this.setSecondaryBtn = function(input)
    {
        if (input) {
            this._secondary_btn.html(input);
        }

        return this;
    };


    /**
     * The primary button text
     */
    this.setPrimaryBtn = function(input)
    {
        if (input) {
            this._primary_btn.html(input);
        }

        return this;
    };


    /**
     * The load trigger name
     */
    this.setLoadTriggerName = function(input)
    {
        if (input) {
            this._load_trigger_name = input;
        }

        return this;
    };


    /**
     * The success trigger name
     */
    this.setSuccessTriggerName = function(input)
    {
        if (input) {
            this._success_trigger_name = input;
        }

        return this;
    };


    /**
     * The failed trigger name
     */
    this.setFailedTriggerName = function(input)
    {
        if (input) {
            this._failed_trigger_name = input;
        }

        return this;
    };


    /**
     * The success message
     */
    this.setSuccessMessage = function(input)
    {
        if (input) {
            this._success_message = input;
        }

        return this;
    };


    /**
     * The failed message
     */
    this.setFailedMessage = function(input)
    {
        if (input) {
            this._failed_message = input;
        }

        return this;
    };


    /**
     * Set the layout
     */
    this.setLayout = function()
    {
        this.reset();
        this.setLayoutContainer();
        this.setLayoutHeader();
        this.setLayoutTitle();
        this.setLayoutCloseBtn();
        this.setLayoutSummary();
        this.setLayoutContent();
        this.setLayoutFooter();
        this.setLayoutSecondaryBtn();
        this.setLayoutPrimaryBtn();
        this.setContentHeight();
    };


    /**
     * Set the layout container
     */
    this.setLayoutContainer = function()
    {
        this._element.append('<div class="overlay-container"></div>');
        this._container = this._element.find('.overlay-container');
    };


    /**
     * Set the layout header
     */
    this.setLayoutHeader = function()
    {
        this._container.append('<div class="overlay-header row"></div>');
        this._header = this._container.find('.overlay-header');
    };


    /**
     * Set the layout title
     */
    this.setLayoutTitle = function()
    {
        this._header.append('<div class="col-xs-10"><div class="overlay-title"></div></div>');
        this._title = this._container.find('.overlay-title');
    };


    /**
     * Set the layout close button
     */
    this.setLayoutCloseBtn = function()
    {
        this._header.append('<div class="col-xs-2 text-right"><button type="button" class="overlay-close"><i class="mdi-content-clear"></i></button></div>');
        this._close = this._container.find('.overlay-close');
    };


    /**
     * Set the layout
     */
    this.setLayoutSummary = function()
    {
        this._container.append('<div class="overlay-summary row" style="display: none;"></div>');
        this._summary = this._container.find('.overlay-summary');
    };


    /**
     * Set the layout content
     */
    this.setLayoutContent = function()
    {
        this._container.append('<div class="overlay-content row"></div>');
        this._content = this._container.find('.overlay-content');
    };


    /**
     * Set the layout footer
     */
    this.setLayoutFooter = function()
    {
        this._container.append('<div class="overlay-footer row"></div>');
        this._footer = this._container.find('.overlay-footer');
    };


    /**
     * Set the layout secondary button
     */
    this.setLayoutSecondaryBtn = function()
    {
        this._footer.append('<div class="col-sm-6"><button type="button" class="overlay-secondary overlay-close btn btn-transparent">Cancel</button></div>');
        this._secondary_btn = this._container.find('.overlay-secondary');
    };


    /**
     * Set the layout primary button
     */
    this.setLayoutPrimaryBtn = function()
    {
        this._footer.append('<div class="col-sm-6 text-right"><button type="button" class="overlay-primary btn btn-transparent">Save</button></div>');
        this._primary_btn   = this._container.find('.overlay-primary');
    };


    /**
     * Set the max height of the content area
     */
    this.setContentHeight = function()
    {
        if (App.window.getHeight() >= this._content.height()) {
            var height = App.window.getHeight();
            if (this._header.length > 0) {
                height = height - this._header.outerHeight();
            }
            if (this._footer.length > 0) {
                height = height - this._footer.outerHeight();
            }
            this._content.attr('style', 'max-height:' + height + 'px !important');
        }
    };


    /**
     * Handle close button
     */
    this.handleCloseBtn = function()
    {
        var that = this;

        $(document).on('click', '.overlay-close', function() {
            that.hide();
        });

        return this;
    };


    /**
     * Handle primary button
     */
    this.handlePrimaryBtn = function()
    {
        var that = this;

        $(document).on('click', '.overlay-primary', function()
        {
            var form = that._element.find('form');
            if (!form) {
                return false;
            }

            var form_url   = form.attr("action");
            var form_data  = new FormData(form[0]);

            // Setup validation
            form.validate({
                ignore : ":not(select:hidden, input:hidden, input:visible, textarea:visible)"
            });

            if (form.valid()) {
                $.ajax({
                    url         : form_url,
                    type        : 'POST',
                    data        : form_data,
                    contentType : false,
                    processData : false,

                    success : function(result) {
                        App.bootstrap.notifyUser(that._success_message, 'success');
                        that._content.html('<div class="overlay-message">' + that._success_message + '</div>');
                        setTimeout(function() {
                            that.hide();
                        }, 2000);
                        $.event.trigger(that._success_trigger_name, [result, that]);
                    },

                    error : function(result, status, errorThrown) {
                        App.bootstrap.notifyUser(that._failed_message, 'danger');
                        that.errors(result, status, errorThrown);
                        $.event.trigger(that._failed_trigger_name, [result, that]);
                    }
                });
            }
        });

        return this;
    };
};


/**
 * When the user clicks the open overlay
 * button create a new object instance
 */
$(document).on('click', '.open-overlay', function(e)
{
    var button = $(this);

    new App.overlay()
        .init()
        .setTitle(button.data('title'))
        .setClass(button.data('class'))
        .setSummary(button.data('summary'))
        .setSuccessMessage(button.data('success'))
        .setFailedMessage(button.data('failed'))
        .setLoadTriggerName(button.data('load-trigger'))
        .setSuccessTriggerName(button.data('success-trigger'))
        .setFailedTriggerName(button.data('failed-trigger'))
        .setSecondaryBtn(button.data('button-secondary'))
        .setPrimaryBtn(button.data('button'))
        .load(button.data('url'));
});