/**
 * App geocode Object
 *
 * @type object
 */
App.geocode = {

    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function()
    {

    },


    /**
     * Format the address data
     *
     * @param response
     * @returns {{line_1: *, line_2: *, city: *, county: *}}
     */
    formatAddress : function(response)
    {
        return {
            line_1 : response['results'][0]['address_components'][1]['long_name'],
            line_2 : response['results'][0]['address_components'][2]['long_name'],
            city   : response['results'][0]['address_components'][3]['long_name'],
            county : response['results'][0]['address_components'][4]['long_name']
        };
    },


    /**
     * Format the Latitude and Longitude
     *
     * @param response
     * @returns {{latitude: *, longitude: *}}
     */
    formatLatLong : function(response)
    {
        return {
            latitude  : response['results'][0]['geometry']['location']['lat'],
            longitude : response['results'][0]['geometry']['location']['lng']
        }
    },


    /**
     * Update the address fields
     *
     * @param data
     */
    updateAddressFields : function(data)
    {
        $('input[name=line_1]').val(data.line_1);
        $('input[name=line_2]').val((data.line_2 !== data.city) ? data.line_2 : '');
        $('input[name=city]').val(data.city);
        $('input[name=county]').val(data.county);
    },


    /**
     * Update the Latitude / longitude fields
     *
     * @param data
     */
    updateLatLongFields : function(data)
    {
        $('input[name=latitude]').val(data.latitude);
        $('input[name=longitude]').val(data.longitude);
    },


    /**
     * Get the latitude and longitude for an address
     *
     * @param address
     */
    getLatLongByAddress : function(address)
    {
        // Send a request to the google geocode api to get the latitude and longitude of the postcode
        $.post('http://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&sensor=false', function(response)
        {
            if (response.status == 'OK') {
                App.bootstrap.notifyUser('Address found', 'success');
                return response;
            }

            App.bootstrap.notifyUser('Sorry this address could not be found', 'danger');
            return false;
        });
    },


    /**
     * Get the address for a specific latitude / longitude
     *
     * @param lat
     * @param lng
     */
    getAddressByLatLong : function(lat, lng)
    {
        // Send a request to the maps api to get the full address
        $.post('http://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + lng + '&sensor=false', function(response)
        {
            if (response.status == 'OK') {
                App.bootstrap.notifyUser('C0-ordinates found', 'success');
                return response;
            }

            App.bootstrap.notifyUser('Sorry these co-ordinates could not be found', 'danger');
            return false;
        });
    }
};