/**
 * App postcode Object
 *
 * @type object
 */
App.postcode = {

    /**
     * Constructor Function
     *
     * Boot function
     */
    init: function() {
        this.registerPlugin();
        this.handle();
        this.handleModalLoad();
    },


    /**
     * Jquery postcode function
     *
     * Add the function to jquery so it can be used
     * anywhere
     */
    registerPlugin: function() {
        (function($) {
            $.fn.PostCodeFinder = function(options) {

                // Loop through each instance found
                return this.each(function()
                {
                    var postcode_input = $(this);

                    var form      = postcode_input.closest('form');
                    var line_1    = form.find('.address-line-1').length > 0 ? form.find('.address-line-1') : form.find('input[name=line_1]');
                    var line_2    = form.find('.address-line-2').length > 0 ? form.find('.address-line-2') : form.find('input[name=line_2]');
                    var line_3    = form.find('.address-line-3').length > 0 ? form.find('.address-line-3') : form.find('input[name=line_3]');
                    var city      = form.find('.address-city').length > 0 ? form.find('.address-city') : form.find('input[name=city]');
                    var county    = form.find('.address-county').length > 0 ? form.find('.address-county') : form.find('input[name=county]');
                    var latitude  = form.find('.address-latitude').length > 0 ? form.find('.address-latitude') : form.find('input[name=latitude]');
                    var longitude = form.find('.address-longitude').length > 0 ? form.find('.address-longitude') : form.find('input[name=longitude]');

                    // Handle the on click event
                    form.on('click', '.post-code-finder', function(e) {
                        e.preventDefault();

                        if (postcode_input.val() !== '') {
                            var postcode = postcode_input.val().toLowerCase().replace(/\s+/g, '');

                            // Send a request to the google geocode api to get the latitude and longitude of the postcode
                            $.post('//maps.googleapis.com/maps/api/geocode/json?address=' + postcode + '&sensor=false', function(response) {
                                console.log(response);

                                if (response.status == 'OK') {
                                    var lat            = response['results'][0]['geometry']['location']['lat'];
                                    var lng            = response['results'][0]['geometry']['location']['lng'];

                                    var results_line_1 = response['results'][0]['address_components'][0]['long_name'];
                                    var results_line_2 = response['results'][0]['address_components'][1]['long_name'];
                                    var results_line_3 = response['results'][0]['address_components'][2]['long_name'];
                                    var results_city   = response['results'][0]['address_components'][3]['long_name'];
                                    var results_county = response['results'][0]['address_components'][4]['long_name'];

                                    // Update the address inputs
                                    latitude.val(lat);
                                    longitude.val(lng);
                                    line_2.val(results_line_2);
                                    line_3.val(results_line_3 !== results_city ? results_line_3 : '');
                                    city.val(results_city);
                                    county.val(results_county);

                                    // If the address is found but has no data warn the user
                                    if (results_line_1 == null || results_city == null || results_county == null) {
                                        App.bootstrap.notifyUser('Postcode found, but could not retrieve all fields, ', 'info');
                                    }

                                    // If their is a google map update it
                                    if ($('.map-canvas').length > 0 || $('#map-canvas').length > 0) {
                                        handleMap();
                                    }

                                    App.bootstrap.notifyUser('Postcode found, address updated', 'success');

                                } else {
                                    App.bootstrap.notifyUser('Sorry this postcode could not be found', 'danger');
                                }
                            });

                        } else {
                            App.bootstrap.notifyUser('Please enter a valid postcode', 'danger');
                        }

                        return false;
                    });
                });
            };
        })(jQuery);
    },


    /**
     * Handle the postcode functionality
     */
    handle: function() {
        if ($('.address-postcode').length > 0) {
            $('.address-postcode').each(function() {
                $(this).PostCodeFinder();
            });

        } else if($('input[name=postcode]').length > 0) {
            $('input[name=postcode]').each(function() {
                $(this).PostCodeFinder();
            });
        }
    },


    /**
     * Handle the modal postcode fields
     */
    handleModalLoad: function() {
        $(document).on('accountModalLoaded', function() {
            App.postcode.handle();
        });
        $(document).on('addressModalLoaded', function() {
            App.postcode.handle();
        });
        $(document).on('invoiceAddressModalLoaded', function() {
            App.postcode.handle();
        });
        $(document).on('siteAddressModalLoaded', function() {
            App.postcode.handle();
        });
    }
};