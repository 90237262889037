/**
 * App typeahead Object
 *
 * @type object
 */
App.typeahead = {

    /**
     * Auto complete element
     */
    autoComplete : null,


    /**
     * Ajax source
     */
    ajaxUrl : null,


    /**
     * Object properties
     */
    results : [],

    category : null,


    /**
     * Options object
     */
    options : {
        highlight : true,
        minLength : 3
    },


    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function()
    {
        App.typeahead.handle();
    },


    /**
     * Get the options
     */
    getOptions : function()
    {
        return App.typeahead.options;
    },


    /**
     * Clear Cache
     */
    clear : function()
    {
        // Return Bloodhound Cleared
        return Bloodhound.clear();
    },


    /**
     * Get the data source
     */
    getDataSource : function()
    {
        // Set the Options for "Bloodhound" Search Engine
        return new Bloodhound({
            initialize     : false,
            queryTokenizer : Bloodhound.tokenizers.whitespace,
            datumTokenizer : Bloodhound.tokenizers.obj.whitespace('name'),
            identify       : function(object)
            {
                return object.name + ' ' + object.combinationattributes;
            },
            dupDetector    : function(a, b)
            {
                return a.id === b.id;
            },
            prefetch       : {
                cache : false,
                url   : this.ajaxUrl
            },
            remote         : {
                url      : this.ajaxUrl + '/%QUERY' + this.category,
                wildcard : '%QUERY'
            }
        });
    },


    /**
     * Reset the field input state
     */
    resetState : function()
    {
        App.typeahead.autoComplete.removeClass('success');
        App.typeahead.autoComplete.removeClass('danger');
    },


    /**
     * Handle selection
     *
     * @param event
     * @param object
     */
    handleSelection : function(event, object)
    {
        if (typeof(object) != 'undefined') {
            $.event.trigger('search:selected', object);
        }
        App.typeahead.resetState();
    },


    /**
     * Handle cursor changed
     *
     * @param event
     * @param object
     */
    handleCursorChanged : function(event, object)
    {

    },


    /**
     * Handle processing
     *
     * @param event
     * @param query
     */
    handleProcessing : function(event, query)
    {
        App.typeahead.resetState();
        if (!App.typeahead.autoComplete.hasClass('.processing')) {
            App.typeahead.autoComplete.addClass('processing');
        }
        $.event.trigger('search:processing');
    },


    /**
     * handle success
     *
     * @param event
     * @param query
     */
    handleSuccess : function(event, query)
    {
        App.typeahead.autoComplete.removeClass('processing');
        if (!App.typeahead.autoComplete.hasClass('.success')) {
            App.typeahead.autoComplete.addClass('success');
        }
        $.event.trigger('search:success');
    },


    /**
     * Handle error
     *
     * @param event
     * @param query
     */
    handleError : function(event, query)
    {
        App.typeahead.autoComplete.removeClass('processing');
        if (!App.typeahead.autoComplete.hasClass('.danger')) {
            App.typeahead.autoComplete.addClass('danger');
        }
        $.event.trigger('search:error');
    },


    /**
     * Get the suggestion template
     */
    getSuggestionTemplate : function(data)
    {
        // Create the opening tag
        var template = '<div data-id="' + data.id + '">';

        // Display id field
        if (App.typeahead.autoComplete.data('display-id') == true) {
            template += ('(' + data.id + ') ');
        }

        // Display sku field?
        if (App.typeahead.autoComplete.data('display-sku') == true && data.sku) {
            template += ('(' + data.sku + ') ');
        }

        // Get the closing tag
        template += (data.name);


        // Display combination field?
        if (App.typeahead.autoComplete.data('display-combination') == true && data.combinationattributes.length > 0) {
            template += (' (' + data.combinationattributes + ') ');
        }

        template += '</div>';

        return template;
    },


    /**
     * Get the empty template
     */
    getEmptyTemplate : function(data)
    {
        return '<div class="empty-message">No matches.</div>';
    },


    /**
     * Handle the type ahead element
     */
    handle : function()
    {
        this.autoComplete = $('.has-auto-complete');

        // Does the autoComplete exist
        if (this.autoComplete.length > 0) {
            this.ajaxUrl  = this.autoComplete.data('ajax-url');
            this.category = this.autoComplete.data('ajax-category');

            if (this.category !== undefined) {
                this.category = '?cat=' + this.category;
            } else {
                this.category = '';
            }

            this.autoComplete.typeahead(this.getOptions(), {
                name       : 'results',
                limit      : 25,
                displayKey : 'key',
                source     : this.getDataSource(),
                templates  : {
                    suggestion : function(data)
                    {
                        return App.typeahead.getSuggestionTemplate(data);
                    }
                }
            });

            this.autoComplete.on('typeahead:selected', function(event, object)
            {
                App.typeahead.handleSelection(event, object);
            });

            this.autoComplete.on('typeahead:cursorchanged', function(event, object)
            {
                App.typeahead.handleCursorChanged(event, object);
            });

            this.autoComplete.on('typeahead:asyncrequest', function(event, query)
            {
                App.typeahead.handleProcessing(event, query);
            });

            this.autoComplete.on('typeahead:asyncreceive', function(event, query)
            {
                App.typeahead.handleSuccess(event, query);
            });

            this.autoComplete.on('typeahead:asynccancel', function(event, query)
            {
                App.typeahead.handleError(event, query);
            });
        }
    }
};
