/**
 * App pin Object
 *
 * @type object
 */
App.pin = {

    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function() {
        this.handleToggle();
    },


    /**
     * Add pin to record
     *
     * @param element
     */
    add: function(element) {
        element.removeClass('btn-default').addClass('active purple').data('state', true);
        this.store(element);
        App.bootstrap.notifyUser('<i class="mdi-maps-place"></i> <strong>Added</strong> to pinned records', 'success');
    },


    /**
     * Remove pin from record
     *
     * @param element
     */
    remove: function(element) {
        element.removeClass('active purple').addClass('btn-default').data('state', false);
        this.store(element);
        App.bootstrap.notifyUser('<i class="mdi-maps-place"></i> <strong>Removed</strong> from pinned records', 'danger');
    },


    /**
     * Update the remote database
     */
    store : function(element) {
        $.ajax({
            type : "POST",
            url  : element.data('ajax'),
            data : {
                id     : element.data('id'),
                type   : element.data('type'),
                state  : element.data('state'),
                _token : App.helper.getToken()
            }
        });
    },


    /**
     * Handle the activity bar open / closed toggle function
     */
    handleToggle: function()
    {
        if (!$('.pin-toggle').length) {
            return;
        }

        $(document).on('click', '.pin-toggle', function(e) {
            e.preventDefault();

            if ($(this).hasClass('active')) {
                App.pin.remove($(this));

            } else {
                App.pin.add($(this));
            }

            return false;
        });
    }
};