/**
 * App modal Object
 *
 * @type object
 */
App.shepherd = new function()
{

    /**
     * Constructor Function
     *
     * Boot function
     */
    this.init = function()
    {
        this.setElements();
        this.buildTour();
        this.addClasses();
        this.bindEvents();
        this.handleTabs();
    };

    /**
     * Initilise the tour object
     *
     * @type {Tour|*}
     */
    this.tour = new Shepherd.Tour({
        defaults : {
            classes        : 'shepherd-element shepherd-open shepherd-theme-arrows',
            showCancelLink : true
        }
    });

    /**
     * Get all steps for the tour
     *
     * @type {*|jQuery|HTMLElement}
     */
    this.steps = $('[data-shepherd-title]');

    /**
     * Bind the object events
     */
    this.bindEvents = function()
    {
        $(document).on('click', this.startBtn.selector, this.startTour);

        $(document).ajaxStop(function() {
            App.shepherd.addClasses();
        });
    };


    /**
     * Set the elements
     */
    this.setElements = function()
    {
        this.startBtn = $('#shepherd-guide');
    };

    /**
     * Add classes for styling on all shepherd objects
     */
    this.addClasses = function()
    {
        $('[data-shepherd-title]').each(function(index, element) {
            $(element).addClass('shepherd-target');
        });
    };

    /**
     * Sort the order of steps and build the tour
     */
    this.buildTour = function()
    {
        // Sort to get the right order..
        this.steps.sort(function(a, b)
        {
            return $(a).data('shepherd-order') - $(b).data('shepherd-order');
        });

        // Build steps with the new order
        $.each(this.steps, function(index, step)
        {
            App.shepherd.buildStep(index, step);
        });
    };

    /**
     * Build the Tour steps
     */
    this.buildStep = function(index, element)
    {
        var $element = $(element);

        // Add class for styling
        $element.addClass('shepherd-target');

        // Get selector details to add step
        var selector = App.helper.getPath($element);
        var position = $element.data('shepherd-position') ? $element.data('shepherd-position') : 'top';

        // Add Shepherd tour step
        this.tour.addStep('fields', {
            title    : $element.data('shepherd-title'),
            text     : $element.data('shepherd-text'),
            attachTo : selector + ' ' + position,
            buttons  : this.buildButtons(index)
        });

    };


    /**
     * Build the buttons for the steps
     */
    this.buildButtons = function(order)
    {
        // Only 1 step
        if (this.steps.length == 1) {
            return [
                this.closeBtn()
            ];
        }

        // First step if more than 1
        if (order == 0) {
            return [
                this.exitBtn(),
                this.nextBtn()
            ];
        }

        // Middle steps
        if (order > 0 && order < this.steps.length - 1) {
            return [
                this.backBtn(),
                this.nextBtn()
            ];
        }

        // Last step
        if (order == this.steps.length - 1) {
            return [
                this.backBtn(),
                this.closeBtn()
            ];
        }
    };

    /**
     * Exit button
     */
    this.exitBtn = function()
    {
        return {
            text    : 'Exit',
            classes : 'shepherd-button-secondary',
            action  : this.tour.cancel
        };
    };

    /**
     * Back button
     */
    this.backBtn = function()
    {
        return {
            text    : 'Back',
            classes : 'shepherd-button-secondary',
            action  : this.tour.back
        };
    };

    /**
     * Next button
     */
    this.nextBtn = function()
    {
        return {
            text    : 'Next',
            action  : this.tour.next,
            classes : 'shepherd-button-example-primary'
        };
    };

    /**
     * Close button
     */
    this.closeBtn = function()
    {
        return {
            text    : 'Close',
            action  : this.tour.cancel,
            classes : 'shepherd-button-example-primary'
        };
    };

    /**
     * Start the Shepherd tour
     */
    this.startTour = function(event)
    {
        event.preventDefault();

        App.shepherd.tour.start();
    };


    /**
     * Close button
     */
    this.handleTabs = function()
    {
        Shepherd.on('show', function() {
            setTimeout(function() {
                var tab = $('.shepherd-enabled').find('[data-toggle="tab"]');

                if (tab.length) {
                    tab.trigger('click');
                }
            }, 100);
        });
    }

};