/**
 * App checkbox Object
 *
 * @type object
 */
App.checkbox = {

    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function()
    {
        App.checkbox.checkAll();
    },


    /**
     * Handle the check all functionality
     */
    checkAll : function()
    {
        $(document).on('click', '[data-action="check-all"] [name="all"]', function() {
            $(this).closest('[data-action="check-all"]').find('[type="checkbox"]').not(this).prop('checked', this.checked);
        });
    }
};