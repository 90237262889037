/**
 * App select2 Object
 *
 * @type object
 */
App.select2 = {

    /**
     * Object properties
     */
    results : [],

    /**
     * Query params
     */
    queryParams : {},


    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function()
    {
        App.select2.handle();
    },


    /**
     * Set the template for the search result
     *
     * @param data
     * @returns {*}
     */
    formatTemplateResult : function(data)
    {
        if (data.loading) {
            return data.text;
        }

        var markup = "<div class='select2-item'><div class='item-name'>" + data.name || data.text + "</div></div>";

        return markup;
    },


    /**
     * Format the selected item display
     *
     * @returns {*}
     */
    formatTemplateSelection : function(data)
    {
        return data.name || data.text;
    },


    /**
     * Ajax object
     */
    getAjax : function(element) {
        return {
            url      : element.data('select-ajax-url'),
            dataType : 'json',
            delay    : 250,
            data     : function(params) {
                return App.select2.getParams(element, params);
            },
            processResults : function(data, page) {
                //App.select2.formatResults(data);
                return {
                    results : data
                }
            },
            cache              : true,
            minimumInputLength : 3
        }
    },


    /**
     * Custom params
     *
     * Query paramaters will be ?q=[term]&page=[page]&param_id=[value]
     *
     * @returns {{}}
     */
    getParams : function(element, params) {
        this.queryParams = {
            q    : params.term,
            page : params.page
        }
        if (element.attr('param-id') || element.prop('param-id')) {
            this.queryParams.param_id = element.attr('param-id') || element.prop('param-id');
        }
        return this.queryParams;
    },


    /**
     * Format results function
     *
     * @param data
     * @returns {Array}
     */
    formatResults : function(data)
    {
        App.select2.results = [];
        $.each(data, function(index, item) {
            App.select2.results.push({
                'id'   : item.id,
                'text' : item.name
            });
        });
    },


    /**
     * Handle the select2 element
     */
    handle : function()
    {
        $('.select2').each(function(index) {
            $(this).select2({
                ajax : App.select2.getAjax($(this)),
                escapeMarkup      : function(markup) {return markup;},
                templateResult    : App.select2.formatTemplateResult,
                templateSelection : App.select2.formatTemplateSelection
            });
        });
    },

    /**
     * Re-build an individual select2
     */
    rebuild: function(element) {
        $(element).select2({
            ajax : App.select2.getAjax($(element))
        });
    }
};


/**
 * Reload the select 2 inside the create account modal
 */
$(document).on('accountModalLoaded', function(e) {
    App.select2.handle();
});